:root{
  --btn-primary-color: #db0011;
  --btn-primary-active-color: #83000a;
  --btn-primary-hover-color: #af000d;
  --logo-url: url("/assets/images/HSBC-logo-en-us-new.svg");
  --header-color: #3e505d;
  --footer-color: #000;
  --footer-font-color: #fff;
}


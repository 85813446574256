html {
  -webkit-tap-highlight-color: transparent;
  font-size: 100%;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}




h1 {
  font-family: "Univers-Next-for-HSBC-Lt", Arial, PingFang TC, PingFang SC, Microsoft JhengHei, Microsoft YaHei, Lantinghei TC, Lantinghei SC, Heiti TC, Heiti SC, simhei, sans-serif;
  font-size: 1.188rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #333;

}

h2 {
  font-size: 1.1875rem;
  line-height: 27px;
}


input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  margin-top: 0;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

input[type="checkbox"] {
  padding: 0;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: 1px solid #929292;
  cursor: pointer;
  /* Show the indicator (dot/circle) when checked */
  outline: none !important;
  background: white;
  position: relative;
}

input[type="checkbox"]::after {
  content: "";
  position: relative;
  display: inline-block;
  vertical-align: top;
  opacity: 0;
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #008580;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

input[type="checkbox"]:hover, input[type="checkbox"]:focus, input[type="checkbox"]:focus:active {
  border-color: #376798;
}

input[type="checkbox"][disabled], input[type="checkbox"].disabled, input[type="checkbox"]:disabled, input[type="checkbox"][readonly] {
  border-color: #929292;
  opacity: 0.5;
}

input[type="checkbox"]::-ms-check {
  border: none;
}

input[type="checkbox"]:checked:after {
  opacity: 1;
}

input[type="checkbox"]:checked:not(:indeterminate):not(.indeterminate)::-ms-check {
  display: inline-block;
  width: 3.5px;
  height: 9px;
  border: solid #008580;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  margin-top: -3px;
  color: transparent;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:indeterminate::-ms-check, input[type="checkbox"].indeterminate::-ms-check {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #929292;
  box-sizing: border-box;
  color: transparent;
  background: url("/assets/images/indeterminate-checkbox.png") no-repeat;
}

input:focus, input:active, input:hover {
  color: #333 !important;
  border-color: #376798 !important;
  box-shadow: none !important;
}

@import 'hsbcfont';

@import "../variables/font";



.font-family-HSBC-Next-Lt {
  font-family: $font-family-hsbc-lt;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-family-HSBC-Next-Md {
  font-family: $font-family-hsbc-md;
}

.font-family-HSBC-Next-Rg {
  font-family: $font-family-hsbc-rg;
}

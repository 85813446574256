/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import '~normalize.css';
@import "styles/fonts/fonts";
@import "styles/variables/variables";
@import "styles/common";

body {
  padding: 70px 0 0;
  background-color: #929292;
  font-family: $font-family-hsbc-rg;
  font-weight: 400;
  line-height: 1.2;
  color: rgba(0, 0, 0, .9);
  font-size: .875rem;
}

.btn {
  display: inline-block;
  line-height: 1;
  padding: 12px 20px;
  font-weight: 300;
  font-family: Univers-Next-for-HSBC-Rg, Arial, PingFang TC, PingFang SC, Microsoft JhengHei, Microsoft YaHei, Lantinghei TC, Lantinghei SC, Heiti TC, Heiti SC, simhei, sans-serif;
  text-decoration: none;
  border-radius: 0;
  white-space: normal;
  transition: none;
  background-color: transparent;
  appearance: none !important;
  font-size: .875rem !important;
  width: 100%;
}



.btn-link:not(:disabled):not(.disabled), .btn-link:not(:disabled):not(.disabled).active, .btn-link:not(:disabled):not(.disabled):focus:active, .btn-link:not(:disabled):not(.disabled):focus:hover, .btn-link:not(:disabled):not(.disabled):hover {
  border: 1px solid transparent;
  color: #333;
  background-color: transparent;
}

.btn-sm:not(:disabled):not(.disabled), .btn:not(:disabled):not(.disabled), .global-list-group .list-item .list-item-header .list-item-toggle:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-sm:not(:disabled):not(.disabled), .btn:not(:disabled):not(.disabled), .global-list-group .list-item .list-item-header .list-item-toggle:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}


//font
.icon::before {
  font: normal normal normal 24px/1 HSBCIcon-Font,sans-serif;
}

.icon-new-window:before {
  font-size: 1rem;
  color: #f9f9f9;
}

.icon-new-window:before {
  content: "";
}


.icon-chevron-down:before {
  font-size: 1rem;
  content: ""
}


//btn
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  font-size: .875rem !important;
}


@font-face {
    font-family: "HSBCIcon-Font";
    src: url("/assets/fonts/HSBCIcon-Font/HSBCIcon-Font.eot");
    src: url("/assets/fonts/HSBCIcon-Font/HSBCIcon-Font.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/HSBCIcon-Font/HSBCIcon-Font.woff") format("woff"),
        url("/assets/fonts/HSBCIcon-Font/HSBCIcon-Font.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Univers-Next-for-HSBC-Th";
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Thin.eot');
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Thin.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Thin.woff2') format("woff2"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Thin.woff') format("woff"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Thin.ttf') format('truetype');
}

@font-face {
    font-family: "Univers-Next-for-HSBC-Lt";
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Light.eot');
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Light.woff2') format("woff2"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Light.woff') format("woff"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Light.ttf') format('truetype');
}

@font-face {
    font-family: "Univers-Next-for-HSBC-Rg";
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Rg.eot');
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Rg.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Rg.woff2') format("woff2"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Rg.woff') format("woff"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Rg.ttf') format('truetype');
}

@font-face {
    font-family: "Univers-Next-for-HSBC-Md";
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.eot');
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.woff2') format("woff2"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.woff') format("woff"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.ttf') format('truetype');
}

@font-face {
    font-family: "Univers-Next-for-HSBC-Bd";
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.eot');
    src: url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.woff2') format("woff2"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.woff') format("woff"),
        url('/assets/fonts/HSBC-UniversNext/UniversNextforHSBCW01-Md.ttf') format('truetype');
}
